import type { FC, ReactElement } from 'react';
import { useState, useEffect } from 'react';

import { requireLegacyWRM } from './helpers/requireLegacyWRM';

type RequireLegacyWRMProps = {
	wrm: string | string[];
	children?: (modules?: unknown[]) => ReactElement | null;
};

type WRMLoadingState = {
	loaded: boolean;
	modules: unknown[];
};

/**
 * React form of requireLegacyWRM
 * Will NOT render children if WRM is not available or no loaded
 */
export const RequireLegacyWRM: FC<RequireLegacyWRMProps> = ({
	wrm,
	children,
}: RequireLegacyWRMProps) => {
	const [wrmLoadingState, setWrmLoadingState] = useState<WRMLoadingState>({
		loaded: false,
		modules: [],
	});

	useEffect(() => {
		requireLegacyWRM(
			wrm,
			(modules) => {
				setWrmLoadingState({
					loaded: true,
					modules,
				});
			},
			() => {},
		);
	}, [wrm]);

	if (process.env.NODE_ENV !== 'production') {
		if (typeof children !== 'function') {
			throw new Error(
				`Children of <RequireLegacyWRM> should be a function.
  Use <RequireLegacyWRM>{() => <div />}</RequireLegacyWRM>
  Not <RequireLegacyWRM><div /></RequireLegacyWRM>`,
			);
		}
	}

	return wrmLoadingState.loaded && children ? children(wrmLoadingState.modules) : null;
};
