import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

import type { ContentHeaderUnifiedQuery as ContentHeaderUnifiedQueryType } from '../__types__/ContentHeaderUnifiedQuery';

import type { UserShape, QueryType } from './byLineTypes';

export const useMemoizedObj = (obj: any): any => {
	const ref = useRef(obj);
	if (!isEqual(ref.current, obj)) {
		ref.current = obj;
	}
	return ref.current;
};

// For powered by templates by tailored experiences
export const getSourceTemplateKey = (data?: QueryType): string | null => {
	if (!data) {
		return null;
	}

	if ('singleContent' in data) {
		return null;
	}

	if ('content' in data) {
		const sourceTemplateEntityId =
			(data as ContentHeaderUnifiedQueryType)?.content?.nodes?.[0]?.metadata
				?.sourceTemplateEntityId || null;

		if (!sourceTemplateEntityId) {
			return null;
		}

		// we don't want to show the powered by template(popup) for the space blueprint
		// blueprints used during space creation should not be exposed to customer as those are internal only
		// internal-only blueprints usually include substring "confluence-space-blueprints" in sourceTemplateEntityId
		return sourceTemplateEntityId.includes('confluence-space-blueprints')
			? null
			: sourceTemplateEntityId;
	}

	return null;
};

export const isFabricEditor = (data?: QueryType): boolean => {
	if (!data) {
		return false;
	}

	if ('singleContent' in data) {
		return false;
	}

	if ('content' in data) {
		const properties =
			(data as ContentHeaderUnifiedQueryType)?.content?.nodes?.[0]?.properties?.nodes || [];
		const editorVersion = properties.find((p) => p?.key === 'editor');
		return editorVersion?.value?.includes('v2') || false;
	}

	return false;
};

export const getArrangedContributors = ({
	owner,
	author,
	lastModifier,
	contributors,
}: {
	owner: UserShape | null;
	author: UserShape;
	lastModifier: UserShape;
	contributors: UserShape[];
}): UserShape[] => {
	let arrangedContributors: UserShape[] = [];

	if (owner !== null) {
		arrangedContributors = [
			// Owner is always shown first
			owner,
			// Then the author
			...(author.userId === owner.userId ? [] : [author]),
			// Then the last person to have edited, unless they're also the author
			...(lastModifier.userId === author.userId || lastModifier.userId === owner.userId
				? []
				: [lastModifier]),
			// Then all the other contributors
			...contributors.filter(
				({ userId }) =>
					userId !== author.userId && userId !== lastModifier.userId && userId !== owner.userId,
			),
		];
	} else {
		arrangedContributors = [
			// Author is always shown first
			author,
			// Then the last person to have edited, unless they're also the author
			...(lastModifier.userId === author.userId ? [] : [lastModifier]),
			// Then all the other contributors
			...contributors.filter(
				({ userId }) => userId !== author.userId && userId !== lastModifier.userId,
			),
		];
	}

	return arrangedContributors;
};

export const toUserProps = (graphqlUser) => ({
	isAnonymous: !graphqlUser?.accountId,
	userId: graphqlUser?.accountId ?? undefined,
	fullName: graphqlUser?.displayName,
	avatarUrl: graphqlUser?.profilePicture?.path,
});
