import gql from 'graphql-tag';

export const ByLineDynamicPropertiesMutation = gql`
	mutation invokeExtension($input: InvokeExtensionInput!) {
		invokeExtension(input: $input) {
			success
			response {
				body
			}
			errors {
				message
				extensions {
					statusCode
				}
			}
		}
	}
`;
