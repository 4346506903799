import { LoadableAfterPaint } from '@confluence/loadable';
export type { ContentState } from './RendererContentState/types';
export { ContentStateColor } from './ContentStateColor';
export { ContentStateStateContainer } from './EditorContentState/ContentStateStateContainer';
export { EditorContentStateQuery } from '../src/EditorContentState/EditorContentStateQuery.graphql';
export type { EditorContentStateQuery as EditorContentStateQueryType } from '../src/EditorContentState/__types__/EditorContentStateQuery';
export type { EditorContentStateQueryVariables } from '../src/EditorContentState/__types__/EditorContentStateQuery';

export const SpaceAdminConfig = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceAdminConfigSpaceAdminConfig" */ './SpaceAdminConfig/SpaceAdminConfig'
			)
		).SpaceAdminConfig,
	name: 'SpaceAdminConfig',
});

export const EditorContentState = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorContentStateEditorContentState" */ './EditorContentState/EditorContentState'
			)
		).EditorContentState,
});

export const EditorContentStateComponent = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorContentStateEditorContentStateComponent" */ './EditorContentState/EditorContentStateComponent'
			)
		).EditorContentStateComponent,
});

export const PageHistoryContentState = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RendererContentStatePageHistoryContentState" */ './RendererContentState/PageHistoryContentState'
			)
		).PageHistoryContentState,
});

export const RendererContentState = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RendererContentStateRendererContentState" */ './RendererContentState/RendererContentState'
			)
		).RendererContentState,
});
