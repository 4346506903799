import React from 'react';

import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { BylineContainer, BylineMessageContainer } from './ContentOwnershipStyles';
import { PageOwnershipBylineContent } from './PageOwnershipBylineContent';
import { PageOwnershipBylinePopupHandler } from './PageOwnershipBylinePopupHandler';
import type { UserShape } from './types';

type PageOwnershipBylineProps = {
	owner: UserShape;
	creator: UserShape;
	createdDate: Date | null;
	templateId: string | null;
	contentId: string | null;
	lastOwner: UserShape | null;
};
export const PageOwnershipByline = ({
	owner,
	creator,
	createdDate,
	templateId,
	contentId,
	lastOwner,
}: PageOwnershipBylineProps) => {
	const isLivePage = useIsLivePage();
	const isNewLivePageBylineFeatureFlagEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.new-live-pages-byline',
	);
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const isNewLivePageByline =
		isLivePage && isNewLivePageBylineFeatureFlagEnabled && isCustomSitesPageTitleFFOn;

	return (
		<BylineContainer>
			<BylineMessageContainer>
				<PageOwnershipBylineContent owner={owner} templateId={templateId} />
			</BylineMessageContainer>
			{!isNewLivePageByline && (
				<PageOwnershipBylinePopupHandler
					creator={creator}
					createdDate={createdDate}
					ownedByAccountId={owner.userId}
					contentId={contentId}
					lastOwner={lastOwner}
				/>
			)}
		</BylineContainer>
	);
};
