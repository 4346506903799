import { useEffect } from 'react';

type InlineDialogCloseManagerHook = {
	popupRef: HTMLElement | null;
	triggerRef: HTMLElement | null;
	onClose: () => void;
};

export const useInlineDialogCloseManager = ({
	onClose,
	popupRef,
	triggerRef,
}: InlineDialogCloseManagerHook): void => {
	useEffect(() => {
		if (!popupRef) {
			return;
		}

		const keyDownEvent = ({ key }: KeyboardEvent) => {
			if (key === 'Escape' || key === 'Esc') {
				onClose();
			}
		};

		const clickEvent = (event) => {
			// Refresh button inside Forge app triggers earlier then clickEvent
			// Forge App starts update and popup does not have event.target anymore
			if (!document.body.contains(event.target)) {
				return;
			}

			// Do not close if interaction was performed on the current popup
			if (popupRef && popupRef.contains(event.target)) {
				return;
			}

			// Do not close if interaction was performed on the trigger container
			if (triggerRef && triggerRef.contains(event.target)) {
				return;
			}

			// Do not close if interaction was performed on the ModalDialog with an error
			if (event.target.closest('.atlaskit-portal-container [role=dialog]')) {
				return;
			}

			onClose();
		};

		window.addEventListener('click', clickEvent);
		window.addEventListener('keydown', keyDownEvent);

		return () => {
			window.removeEventListener('click', clickEvent);
			window.removeEventListener('keydown', keyDownEvent);
		};
	}, [popupRef, triggerRef, onClose]);
};
