import type { FC } from 'react';
import React, { Fragment, useEffect } from 'react';
import debounce from 'lodash/debounce';

type ContentChangeListenerProps = {
	onUpdate: () => void;
	popupRef: HTMLElement | null;
	children?: React.ReactNode;
};

export const ContentChangeListener: FC<ContentChangeListenerProps> = ({
	onUpdate,
	popupRef,
	children,
}) => {
	useEffect(() => {
		if (!popupRef) {
			return;
		}
		const updateWithDelay = debounce(onUpdate, 100);

		// Workaround to update popup position (by using scheduleUpdate function)
		// on any Forge App mutation
		const observer = new MutationObserver(() => {
			updateWithDelay();
		});

		observer.observe(popupRef, {
			childList: true,
			subtree: true,
		});

		return () => {
			observer.disconnect();
		};
	}, [popupRef, onUpdate]);

	return <Fragment>{children}</Fragment>;
};
